(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.championships.controller:ChampionshipsCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.championships')
    .controller('ChampionshipsCtrl', ChampionshipsCtrl);

  function ChampionshipsCtrl($filter, AclService, championships, genders, sports, categories, phases) {
    var vm = this;
    vm.ctrlName = 'ChampionshipsCtrl';
    vm.can = AclService.can;

    vm.sports = sports;
    vm.categories = categories;
    vm.genders = genders;
    vm.phases = phases;

    var championshipList = buildChampionshipModelList(championships);

    vm.data = {
      cardWidth: 80,
      title: 'CHAMPIONSHIPS.LIST',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'name', name: 'CHAMPIONSHIPS.NAME', canOrder: true, orderBy: 'nameOrder'},
        {id: 'sport', name: 'SPORT', canOrder: true},
        {id: 'category', name: 'CATEGORY', canOrder: true, orderBy: 'categoryOrder', orderDesc: true},
        {id: 'gender', name: 'GENDER', canOrder: true},
        {id: 'phase', name: 'PHASES.PHASE', canOrder: true, orderBy: 'phaseOrder'},
        {id: 'phaseType', name: 'PHASES.PHASE_TYPE', canOrder: true}
      ],
      state: {
        slink: 'home.championships.championship', params: [
          {key: 'championshipId', value: 'id'}
        ]
      },
      defaultOrderBy: ['phaseOrder', 'sport', 'categoryOrder', 'gender'],
      defaultTypeOrderBy: [true, true, false, true],
      values: championshipList
    };

    function buildChampionshipModelList(championships) {
      var championshipList = [];
      angular.forEach(championships, function (championship) {
        var category = findItemById(vm.categories, championship.categoryId);
        var phase = findItemById(vm.phases, championship.phaseId);
        var championshipModel = {
          id: championship.id,
          name: championship.name,
          nameOrder: $filter('lowercase')(championship.name),
          sport: $filter('translate')('SPORTS.' + findItemById(vm.sports, championship.sportId).name),
          category: $filter('translate')('CATEGORIES.' + category.name),
          categoryOrder: category.startYear,
          gender: $filter('translate')(findItemById(vm.genders, championship.genderId).name),
          phase: phase.name,
          phaseOrder: $filter('lowercase')(championship.phase),
          phaseType: $filter('translate')('PHASES.PHASES_TYPES.' + phase.type)
        };
        championshipList.push(championshipModel);
      });
      return championshipList;
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

  }
}());
